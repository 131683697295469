import { Box, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { TenantSubsPlanStripeCouponListType } from '../tenant-subs-plan-types';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import {
  createTenantSubsPlanCoupon,
  fetchtenantSubsPlaneCouponList,
} from '../tenant-subs-plan-actions';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { CouponTable } from './components/CouponsTable';
import { useAuth } from '../../auth/auth-hook';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { CreateCouponModal } from './components/CreateCouponModal';
import { CoponIcon } from '../../../shared/components/icons/customIcons';
import { CardSection } from '../../../shared/components/card/CardSection';
import { CouponTableFilter } from './components/CouponTableFilter';
import { couponFilterStore } from './coupon-store';
import { useTableSort } from '../../../shared/components/table/useTableSort';

export const CouponsView: FC = () => {
  const [open, setOpen] = useState(false);
  const { tenant } = useAuth();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [loading, setLoading] = useState(true);
  const [couponList, setCouponList] =
    useState<TenantSubsPlanStripeCouponListType>();
  const filter = useStore(couponFilterStore);
  const { sort, handleSort } = useTableSort();

  const [call] = useCallAction(fetchtenantSubsPlaneCouponList, {
    onCompleted: (data) => {
      setCouponList(data);
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      openSnackbarAction(error.message, TypeMessage.ERROR);
    },
  });

  const refetch = useCallback(() => {
    if (!tenant) return;

    setLoading(true);
    call({
      tenant,
      page: pagination.page,
      pageSize: pagination.pageSize,
      status: filter.status,
      expired: filter.expired,
      orderBy: sort,
    });
  }, [tenant, pagination, filter, sort, call]);

  const [callCreate, loadingCreate] = useCallAction(
    createTenantSubsPlanCoupon,
    {
      onCompleted: () => {
        openSnackbarAction('Cupón creado con éxito', TypeMessage.SUCCESS);
        setOpen(false);
        refetch();
      },
      onError: (error) => {
        openSnackbarAction(error.message, TypeMessage.ERROR);
      },
    },
  );

  useEffect(() => {
    if (!tenant) return;

    setLoading(true);
    call({
      tenant,
      page: pagination.page,
      pageSize: pagination.pageSize,
      status: filter.status,
      expired: filter.expired,
      orderBy: sort,
    });
  }, [call, tenant, pagination, filter, sort]);

  return (
    <>
      <CreateCouponModal
        open={open}
        handleClose={() => setOpen(false)}
        buttonColor={tenant?.identityColor}
        loading={loadingCreate}
        isAnnualPlan={false}
        onSubmit={(data) => {
          if (!tenant) return;
          callCreate({ tenant, ...data });
        }}
      />

      <CardSection
        title={
          <Stack
            direction={'row'}
            width={'100%'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ py: '15px' }}>
            <Box>
              <Typography
                fontSize={{ sx: '16px', md: '20px' }}
                fontWeight={600}>
                Cupones
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <ButtonDefault
                onClick={() => setOpen(true)}
                style={{
                  width: 157,
                  height: 48,
                  textTransform: 'inherit',
                  fontWeight: 700,
                  gap: 8,
                  fontSize: 16,
                  background: tenant?.identityColor ?? undefined,
                }}>
                <CoponIcon sx={{ fontSize: '16px' }} /> Crear cupón
              </ButtonDefault>
              <CouponTableFilter />
            </Box>
          </Stack>
        }>
        <CouponTable
          data={couponList?.data || []}
          loading={loading}
          total={couponList?.total || 0}
          pagination={pagination}
          refetch={refetch}
          handleSort={handleSort}
          hanChangePagination={(value) => {
            setLoading(true);
            setPagination(value);
          }}
        />
      </CardSection>
    </>
  );
};
